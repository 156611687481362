<!-- About.vue -->
<template>
  <div>
    <div class="about-warp">
      <h1 class="custom-font guwen">陈大师</h1>
      <div class="custom-font guwen zhengwen">
        <p>陈大师乃卸岭力士后，少年游匪帮，颠沛流离。早岁湘西大山间，群匪之中，身怀绝技，不羁之气。意气风发，行走江湖，一朝古墓寻宝，因奸人陷害，遭逢不幸，双目失明。命途多舛，却因祸得福，遇仙山高人，授以五行八卦之术，风水命理，晦涩玄奥，悉心钻研。</p>
<P>学成下山，以卜算为生。性豪迈而不凡，乐善好施，乐助人间。算命先生，自古有之，探天机以透未来，改造化以顺人心。虽说此道，常有五弊三缺之说，然陈师自视流转人世，一切皆是宿命安排。</P>
<P>如今居于此界，立下誓愿，广结善缘。愿为迷途之人指引方向，为悲苦之众解惑释疑。天地之大，无奇不有；人心之复杂，难以捉摸。陈师在此恭候，愿诸君多加提携，共谋未来。</p>
      </div>
    </div>
    <el-row class="about-catct" :gutter="12">
      <el-col :span="8">
        <el-card class="abou-card" shadow="hover">
          <i class="el-icon-s-home"></i> 聯繫我們 tomieweb#gmail.com
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="abou-card" shadow="hover">
          <i class="el-icon-mobile-phone"></i> X賬號 @tomiezhang
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card class="abou-card" shadow="hover">
          <i class="el-icon-s-promotion"></i> 電報群組
        </el-card>
      </el-col>
    </el-row>

    </div>
  </template>
  <style scoped>
  .abou-card {
    background:#bebebe;
  }
  .about-warp{
    margin: 4rem auto;
    width: 80%;
    height: 300px;
    color: #3c3c3c;
    line-height: 1.3rem;
    overflow: hidden;
  }
  .about-warp p {
    font-size:1.2rem;
    line-height: 1.3em;
    letter-spacing: 0.8rem; 
  }
  h1 {
    font-size: 3em;
    text-align: center;
    float: right;
    color: #3c3c3c;
  }
@font-face {
  font-family: 'LuoShenHua';
    src: url('../assets/AaLuoShenHua-2.woff') format('woff');
    /* 其他格式的字体文件 */
  }

  .custom-font {
    font-family: 'LuoShenHua', cursive; /* 使用自定义字体 */
  }
  .guwen {
    writing-mode: vertical-rl; /* 文字从上到下，从右到左排列 */
    text-orientation: upright; /* 保持文字正直不旋转 */
  }
  .zhengwen{
    height: 300px;
    max-width: 70%;
    float: right;
  }
  .about-catct {
    margin:2rem auto 5rem auto;
  }
</style>
  <script>
  export default {
    methods: {
      goToHome() {
        this.$router.push('/');
      },
    },
  };
  </script>
  