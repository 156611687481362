<template>
    <el-container class="Chat-box">
        <el-header class="Chat-box-head" style="height: auto;">
        <div>{{username}} | <template v-if="(freeTimes - free) > 0"><span>免費次數{{(freeTimes-free)}}</span></template><template v-else><span>免費次數 0</span></template> | <span>付費次數 {{cardcount}}</span>  |  <el-button class="logout" data-tianji-event="chat-logout"  @click="logOut">退出登錄</el-button>   <el-button class="sharefriend" type="info" data-tianji-event="chat-share"  @click="copyText">分享給朋友</el-button>    <el-button class="sharefriend" type="info" data-tianji-event="chat-chongzhi"  @click="chong_dialogFormVisible=true">充值付费点数</el-button></div>
        <el-dialog title="充值卡密" :visible.sync="chong_dialogFormVisible">
          <el-form :model="chong_form">
            <el-form-item label="卡密" :label-width="formLabelWidth">
              <el-input v-model="chong_form.cardkey" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="goAndBuy" class="chat-goAndBuy">購買卡密</el-button>
            <el-button @click="chong_dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="chong_submit">確 定</el-button>
          </div>
        </el-dialog>
        </el-header>
        <el-main class="Chat-box-body" ref="scrollList" >
            <ul class="infinite-list">
                <li :class="item.role==='human'?'infinite-list-human':'infinite-list-ai'" v-for="(item,index) in chatList" :key="index">
                    <template v-if="item.role==='human'">
                        <div class="chat-list-hand">
                            <el-avatar icon="el-icon-user-solid"></el-avatar>
                            <p class="txtname">{{username}}</p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="chat-list-hand">
                            <el-avatar  :src="imageSrc"></el-avatar>
                            <p class="txtname">陳大師</p>
                        </div>
                    </template>
                    <div class="chat-list-body">{{ item.text }}</div>
                </li>
            </ul> 
        </el-main>
        <el-footer height="auto"  class="Chat-box-foot">
            <div class="Chat-tool-bar-center" ref="chatToolBarCenter">
                <el-tooltip content="停止输出" placement="top" effect="light">
                    <el-button @click="stopTalk" icon="el-icon-video-pause" circle></el-button>
                </el-tooltip>
                <el-tooltip content="重试" placement="top" effect="light">
                    <el-button @click="reSendMsg" icon="el-icon-refresh" circle></el-button>
                </el-tooltip>
                <el-tooltip content="语音" placement="top" effect="light">
                    <el-button @click="ctrlVoice" ref="voicebutton" icon="el-icon-bell" circle></el-button>
                </el-tooltip>
                <audio ref="audioPlyer" style="width: 2px;visibility: hidden;"></audio>
            </div>
                
            <el-row :gutter="10" class="Chat-tool-bar">
                <el-col :xs="24" :sm="4" v-for="button in buttons" :key="button.label">
                <el-button icon="el-icon-magic-stick" class="Chat-tool-bar-button" :disabled="isButtonDisabled" :type="button.type" style="width: 100%;" data-tianji-event="chat-quick-button"  @click="quickClik(button.msg)">{{ button.label }}</el-button>
                </el-col>
            </el-row>
            <el-input v-model="input" placeholder="與大師交流" @keyup.enter.native="checkEnterKey"  :disabled="isButtonDisabled"></el-input>
            <el-button class="Chat-send" icon="el-icon-s-promotion"  :disabled="isButtonDisabled" type="primary" data-tianji-event="chat-main" @click="
            sendMsg">問大師</el-button>
        </el-footer>
    </el-container>
</template>
<script>
const serverHost = process.env.VUE_APP_serverHost;
const userproxyports = process.env.VUE_APP_userproxyports;
const userprefix = process.env.VUE_APP_userprefix;
const wsprefix = process.env.VUE_APP_wsprefix;
const wsports = process.env.VUE_APP_wsports;
import axios from 'axios';
export default {
    data() {
      return {
        base_api: userprefix+'://'+serverHost+':'+userproxyports+'/api',
        base_ws: wsprefix+'://'+serverHost+':'+wsports+'/ws',
        username: JSON.parse(localStorage.getItem('user')).username,
        free:JSON.parse(localStorage.getItem('user')).count,
        voice_path: userprefix+'://'+serverHost+':'+wsports+'/voice',
        cardcount:0,
        token: localStorage.getItem('authToken'),
        chong_dialogFormVisible: false,
        input: '',
        freeTimes: 30,
        isButtonDisabled: false,
        isWsDown:true,
        insterIndex: 0,
        isWsOpen:false,
        imageSrc: require('@/assets/avatar.png'),
        chong_form: {
            cardkey: ''
        },
        audioPlyers:true,
        stopTalking:false,
        chatList: [
            {
                "text":"客官您好，有何貴幹？",
                "role":"AI"
            }
        ],
        buttons: [
            { label: '快捷：問八字', type: 'info',msg:'我的八字如何？'},
            { label: '快捷：紫薇排盤', type: 'info',msg:'大師幫我看下我的紫薇排盤?' },
            { label: '快捷：解夢', type: 'info',msg:'幫我解個夢' },
            { label: '快捷：搖一卦', type: 'info',msg:'給我搖一掛算下吉凶' },
            { label: '快捷：問運勢', type: 'info',msg:'龍年我的運勢如何？' },
            { label: '快捷：姓名打分', type: 'info',msg:'給我的名字打個分' },
            // 更多按钮...
        ],
      }
    },
    created(){
        this.getUserInfo();
        this.getFreetimeFromConfig();
    },
    mounted(){
        this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          customClass:'chat-loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.loading.close();
    },
    beforeDestroy() {
        if (this.ws) {
            console.log("销毁前关闭ws")
            clearInterval(this.pongInterval);
            this.isWsDown = true;
            this.ws.close();
            this.ws = null;
        }
    },
    methods: {
        checkEnterKey(event) {
            if (event.key === 'Enter') {
                this.sendMsg();
            }
        },
        ctrlVoice(){
            const button = this.$refs.voicebutton;
            const audio = this.$refs.audioPlyer;
            const iconElement = button.$el.querySelector('i');
            if (iconElement.classList.contains("el-icon-bell")) {
                iconElement.classList.remove("el-icon-bell");
                iconElement.classList.add("el-icon-close-notification");
                this.audioPlyers= false;
                audio.pause();
            } else {
                iconElement.classList.remove("el-icon-close-notification");
                iconElement.classList.add("el-icon-bell");
                this.audioPlyers= true;
                audio.play();
            }
        },
        reSendMsg(){
            this.stopTalk();
            this.input = this.resendInput; //重新发送消息
            //重新发送消息
            this.sendMsg();
        },
        stopTalk(){
            if(this.ws!==null){
                clearInterval(this.pongInterval);
                this.isWsDown = true;
                this.ws.close();
                this.ws = null;
            }
        },
        goAndBuy(){
            window.open('https://takecareara.com/', '_blank');
        },
        chong_submit(){
            //充值付费点数
            axios.post(this.base_api+'/paycard', {
                cardkey: this.chong_form.cardkey
            }, {
                headers: {
                    'Authorization': 'Bearer '+this.token
                }
            }).then(response => {
                console.log(response.data);
                this.$message({
                    message: '充值成功！',
                    type: 'success'
                });
                this.chong_dialogFormVisible = false;
                this.getUserInfo();
            }).catch(error => {
                console.log(error);
                this.$message({
                    message: '充值失敗！'+'{'+error.response.data.message+'}',
                    type: 'warning',
                });
            });
        },
        quickClik(msg){
            this.input = msg;
            this.sendMsg();
        },
        getFreetimeFromConfig(){
            //从服务器获取免费次数
            axios.get(this.base_api+'/free-time').then(response => {
                console.log(response.data);
                this.freeTimes = parseInt(response.data.freeTime);
            }).catch(error => {
                console.log(error);
            })
        },
        async copyText() {
            const links = JSON.parse(localStorage.getItem('user')).referralCode;
            const text = "https://shensuan.me/reg?refcode=" + links;

            // 创建一个 textarea 元素，设置其内容为你想要复制的文本
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);

            // 选中 textarea 的内容，然后执行 copy 命令
            textarea.select();
            const successful = document.execCommand('copy');

            // 移除 textarea 元素
            document.body.removeChild(textarea);

            if (successful) {
                this.$message({
                    message: '複製成功，快轉發給朋友註冊，每人最高可獲得15次免費占卜機會！',
                    type: 'success'
                });
            } else {
                this.$message({
                    message: '複製失敗!',
                    type: 'warning'
                });
            }
        },
        logOut(){
            //退出登录
            localStorage.removeItem('authToken');
            localStorage.removeItem('user');
            console.log("销毁前关闭ws")
            clearInterval(this.pongInterval);
            if(this.ws){
            this.ws.close();
            this.isWsDown = true;
            this.ws = null;
            }
            this.$router.push('/');
        },
        scrollToBottom() {
            //滚动到底部
            this.$nextTick(() => {
                const scrollContainer = this.$refs.scrollList.$el; // 获取真正的 DOM 元素
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            });

        },
        sendMsgGo(){
            //更新用户信息
            this.getUserInfo();
            //发送消息
            this.ws.send(this.input);
            this.chatList.push({
                "text":this.input,
                "role":"human"
            },{
                "text":"對方正在輸入...",
                "role":"AI"
            });
            this.scrollToBottom();
            this.resendInput = this.input;
            this.input = '';
            
        },
        sendMsg(){
            //判断有没有输入
            if(this.input == ''){
                this.$message({
                    message: '請輸入問題！',
                    type: 'warning'
                });
                return;
            }
            this.stopTalking = false;
             //点按钮发送就不可以再按，直到服务器输出完毕
            this.isButtonDisabled = true;
            if(this.isWsDown){
                console.log('sendMsg重新连接ws!');
                if(this.pongInterval){
                    clearInterval(this.pongInterval);
                }
                //连接断开，重新连接
                this.initWebSoket().then(()=>{
                    console.log('连接成功');
                    //连接后发送
                    this.sendMsgGo()
                }).catch((error) => {
                    this.$message({
                        message: '後台鏈接失敗！自动重试！'+'{'+error+'}',
                        type: 'warning',
                    });
                    setTimeout(()=>this.initWebSoket(), 300)
                })
            }else{
                this.sendMsgGo()
            }
            
        },
        playSound(){
            console.log("音频准备好了!")
            console.log(this.uid)
            const audio = this.$refs.audioPlyer;
            audio.src = this.voice_path + '/' + this.uid + '.mp3';
            if(this.audioPlyers){
                audio.play();
            }
        },
        initWebSoket() {
            return new Promise((resolve, reject) => {
                this.ws = new WebSocket(this.base_ws + '?token=' + this.token);
                this.ws.onopen = () => {
                    console.log('initWebSoket连接成功');
                    this.loading.close();
                    this.isWsDown = false;
                    resolve();  // 连接打开时解析 Promise
                    // 设置定时器，定期向服务器发送 Pong 消息
                    this.pongInterval = setInterval(() => {
                        if (this.ws.readyState === WebSocket.OPEN) {
                            this.ws.send("Pong");
                        }
                    }, 3000);  // 每 3秒发送一次 Pong 消息
                }
                this.ws.onmessage = (msg) => {
                    if (msg.data === "Ping") {
                        // 收到服务器的心跳消息，回复 "Pong"
                        console.log('收到服务器的心跳消息，回复 "Pong"');
                        this.ws.send("Pong");
                    }else if(msg.data == "##VOICEOK##"){
                        //合成音频成功
                        this.playSound();
                    }else if(msg.data == "##END##"){
                        //输出结束
                        this.isButtonDisabled = false;
                    }else if(!this.isWsDown) {
                        console.log("send msg: "+msg.data);
                        // 处理其他消息...
                        //处理的时候不能再输入
                        this.typeText(msg.data);
                        this.scrollToBottom();
                    }  
                }
                this.ws.onclose = (event) => {
                    console.log('连接关闭，代码：', event.code, '原因：', event.reason);
                    this.isWsDown = true; // 连接关闭时标记
                    this.isButtonDisabled = false;
                    clearInterval(this.pongInterval);
                    if(!this.stopTalking){
                        this.typeText("对不起连接出错了，请刷新页面再试一次!");
                    }
                }
                this.ws.onerror = (error) => {
                    this.isWsDown = true; // 连接关闭时标记
                    console.log('连接错误');
                    reject(error);  // 连接出错时拒绝 Promise
                    this.isButtonDisabled = false;
                    clearInterval(this.pongInterval);
                    if(!this.stopTalking){
                        this.typeText("对不起连接出错了，请刷新页面再试一次!");
                    }
                }
            });
        },
        typeText(msg){
            //正在输入
            this.isButtonDisabled = true;
            if(this.stopTalking){
                //停止输入
                this.isButtonDisabled = false;
                return;
            }
            const loadingItem = this.chatList.find(item => item.text === "對方正在輸入...");
            if (loadingItem) {
                const loadingItemIndex = this.chatList.findIndex(item => item === loadingItem);
                this.insterIndex = loadingItemIndex;
                this.chatList[this.insterIndex].text = "";
            }
            this.chatList[this.insterIndex].text += msg; 
        },
        cantUse(){
            //不能使用
            this.$message({
                showClose: true,
                message: '免費次數已使用完，請分享給朋友獲得免費次數！',
                type: 'warning',
                duration:0
            });
            this.isButtonDisabled = true;
        },
        getUserInfo(){
            //从服务器获取用户信息
            console.log(this.token);
            axios.get(this.base_api+'/profile', {
                headers: {
                    'Authorization': 'Bearer '+this.token
                }
            }).then(response => {
                console.log(response.data);
                this.uid = response.data.user._id;
                this.username = response.data.user.username;
                this.free = response.data.user.count;
                this.cardcount = response.data.user.cardcount;
                if(this.cardcount>0){
                    //充值了付费点数
                    console.log("付费:"+this.cardcount)
                }else{
                    //没有付费过
                    if(this.freeTimes - this.free <= 0){
                        this.cantUse();
                        //AI提示免费次数用完了
                        /*this.chatList.push({
                            "text":"客官，起卦是要消耗銀兩的，您的免費次數已經用完了，請分享給朋友獲得免費次數！",
                            "role":"AI"
                        });*/
                    }
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }
}
</script>
<style scoped>
    .Chat-box {
        width: 90%;
        margin: 3rem auto;
    }
    .Chat-box-head {
        width: 100%;
        height: auto;
        border: 1px dashed #fff;
        /*background: #969696;*/
        padding: 1rem;
        clear: both;
        color:#954C3A;
        font-weight: bold;
    }
    .Chat-send {
        width: 100%;
        margin:1rem auto;
    }
    .el-footer {
        padding:0;
    }
    .Chat-box-body {
        position: relative;
        width: 100%;
        height: 600px;
        border: 1px dashed #fff;
        background:#dddddd;
        margin:1rem auto;
        overflow-y: auto;
        z-index: 999;
    }
    .infinite-list {
        width: 100%;
        list-style: none;
        clear: both;
        padding: 0;
        margin:0;
        margin-left:0;
    }
    .infinite-list-human {
        width: 100%;
        height: auto;
        list-style: none;
        clear: both;
        color:#954C3A;
        overflow: auto;
        margin:1rem 0;
    }
    .infinite-list-human .chat-list-hand {
        float: left;
        text-align: center;
        
    }
    .infinite-list-human .chat-list-hand .txtname {
        color: #303030;
        margin:0;
        font-size:0.8rem;
    }
    .infinite-list-human .chat-list-body {
        float: left;
        color:#ffffff;
        background:#1ea204;
        border-radius: 1rem;
        margin-left:0.5rem;
        padding:1rem;
        max-width: 90%;
    }
    .infinite-list-ai {
        width: 100%;
        height: auto;
        list-style: none;
        clear: both;
        color:#954C3A;
        overflow: auto;
        margin:1rem 0;
    }
    .infinite-list-ai .chat-list-hand {
        float: right;
        text-align: center;
        
    }
    .infinite-list-ai .chat-list-hand .txtname {
        color: #303030;
        margin:0;
        font-size:0.8rem;
    }
    .infinite-list-ai .chat-list-body {
        float: right;
        background:#e7e7e7;
        border-radius: 1rem;
        margin-right:0.5rem;
        padding:1rem;
        max-width: 90%;
    }
    .Chat-box-foot {
        width: 100%;
        height: auto;
        padding: 0;
        clear: both;
    }
    .Chat-tool-bar{
        margin: 1rem auto;
    }
    .Chat-tool-bar-button{
        margin-bottom:1rem;
        background:none;
        border:1px dashed #717171;
        color:#303030;
        text-align: left;
        overflow: hidden;
    }
    @media (max-width: 992px) {
        .Chat-box-head{
            font-size: 14px;
        }
        .logout,.sharefriend{
            display: blcok;
            width: 100%;
            margin:0.4rem auto;
        }
        .Chat-box-body{
            height: 400px;
        }
        .infinite-list-human .chat-list-body{
            max-width:50%;
        }
        .infinite-list-human .chat-list-body{
            max-width:60%;
        }
        /* 显示前三项 */
        .Chat-tool-bar .el-col:nth-child(-n+3) {
            display: block;
        }

        /* 不显示第四项及之后的项 */
        .Chat-tool-bar .el-col:nth-child(n+4) {
            display: none;
        }

    }
    .chat-goAndBuy{
        background: #21c700;
        color:#fff
    }
    .Chat-tool-bar-center{
        width: 100%;
        margin:0.5rem auto;
        text-align: center;
    }
</style>