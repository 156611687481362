<template>
  <el-container class="warp-content">
      <el-header>登錄賬號</el-header>
      <el-form :model="ruleForm" :rules="rules" label-width="6rem"  ref="ruleForm" class="Login-from">
      <el-main>
          
              <el-form-item label="用戶名" prop="username">
                  <el-input v-model="ruleForm.username" placeholder="請輸入用戶名" ></el-input>
              </el-form-item>
              <el-form-item label="密 碼" prop="password">
                  <el-input v-model="ruleForm.password" placeholder="請輸入密碼"  show-password></el-input>
              </el-form-item>
          
      </el-main>
      <el-footer>
          <el-form-item label-width="0">
              <el-button type="primary" data-tianji-event="login-sub" @click="submitForm('ruleForm')">立刻登錄</el-button>
              <el-button data-tianji-event="login-reg" @click="gotoreg">註冊賬號</el-button>
          </el-form-item>
      </el-footer>
      </el-form>
  </el-container>
  </template>
  
  <script>
  const serverHost = process.env.VUE_APP_serverHost;
  const userproxyports = process.env.VUE_APP_userproxyports;
  const userprefix = process.env.VUE_APP_userprefix;
  import { isLoggedIn } from './../authService';
  import axios from 'axios';
  export default {
      data() {
        return {
          base_api: userprefix+'://'+serverHost+':'+userproxyports+'/api',
          ruleForm: {
            username: '',
            password: '',
          },
          rules: {
            username: [
              { required: true, message: '請輸入用戶名稱', trigger: 'blur' },
              { min: 3, max: 20, message: '長度在3到20個字符', trigger: 'blur' }
            ],
            password: [
              { required: true, message: '請輸入用戶密碼', trigger: 'blur' },
            ],
          }
        };
      },
      created() {
        //检查是否登录,如果已经登录,则跳转到聊天页面
        if(isLoggedIn()){
          this.$router.push('/chat');
        }
      },
      methods: {
        gotoreg() {
          this.$router.push('/reg');
        },
        submitForm(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              axios.post(this.base_api+'/login', this.ruleForm)
              .then(response => {
                // 处理响应数据,自动登录
                console.log(response.data);
                const token = response.data.token;
                const user = response.data.user;
                localStorage.setItem('authToken', token);
                localStorage.setItem('user', JSON.stringify(user));
                this.$message({
                  message: '登錄成功!',
                  type: 'success'
                })
                this.$router.push('/chat');
              })
              .catch(error => {
                // 处理错误情况
                if(error.response.data){
                  console.log(error.response.data.message);
                  this.$message.error('登錄失敗,请稍后重试!');
                }
                
              });
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        }
      }
    }
  </script>
  <style scoped>
  .warp-content {
    margin:2rem auto;
    border:1px dashed #ccc;
    text-align: center;
  }
  
  </style>