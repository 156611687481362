<template>
     <div class="yinyang"></div>
  </template>
  
  <script>
  export default {
    name: 'YinYang',
  };
  </script>
  
  <style scoped>
  .yinyang {
  position: relative;
  background: #d8d8d8;
  height: 200px;
  border-color: #3c3c3c;
  border-style: solid;
  width: 100px; 
  border-width: 2px 100px 2px 2px;
  border-radius: 50%;
  animation: roll 40000s infinite;
  box-shadow:0px 0px 100px #ac7f74;
} 

.yinyang:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  background: #d8d8d8;
  border: 36px solid #3c3c3c;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  }


.yinyang:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  background: #3c3c3c;
  border: 36px solid #d8d8d8;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}


@keyframes roll {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(3600000deg);
  }
}
  </style>
  