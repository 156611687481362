<template>
  <el-container class="warp-content">
      <el-header>注册賬號</el-header>
      <el-form :model="ruleForm" :rules="rules" label-width="6rem"  ref="ruleForm" class="Login-from">
      <el-main>
          
              <el-form-item label="用户名" prop="username">
                  <el-input v-model="ruleForm.username" placeholder="請輸入用戶名" ></el-input>
              </el-form-item>
              <el-form-item label="電郵" prop="email">
                  <el-input v-model="ruleForm.email" placeholder="請輸入您的電子郵件" ></el-input>
              </el-form-item>
              <el-form-item label="密 碼" prop="password">
                  <el-input v-model="ruleForm.password" placeholder="請輸入密碼"  show-password></el-input>
              </el-form-item>
              <el-form-item label="邀请码" prop="refcode">
                  <el-input v-model="ruleForm.refcode" placeholder="請輸入邀请码"></el-input>
              </el-form-item>
          
      </el-main>
      <el-footer>
          <el-form-item  label-width="0">
              <el-button type="primary" data-tianji-event="reg-sub" @click="submitForm('ruleForm')">註 冊</el-button>
              <el-button @click="resetForm('ruleForm')" >重 置</el-button>
          </el-form-item>
      </el-footer>
      </el-form>
  </el-container>
  </template>
  
  <script>
  const serverHost = process.env.VUE_APP_serverHost;
  const userproxyports = process.env.VUE_APP_userproxyports;
  const userprefix = process.env.VUE_APP_userprefix;
  import { isLoggedIn } from './../authService';
  import axios from 'axios';
  export default {
      data() {
        return {
          base_api: userprefix+'://'+serverHost+':'+userproxyports+'/api',
          ruleForm: {
            username: '',
            password: '',
            email: '',
            refcode: '',
          },
          rules: {
            username: [
              { required: true, message: '請輸入用戶名稱', trigger: 'blur' },
              { min: 3, max: 20, message: '長度在3到20個字符', trigger: 'blur' },
              { validator: this.validateNoChinese, trigger: 'blur' }
            ],
            email: [
              { required: true, message: '請輸入用戶的電郵', trigger: 'blur' },
              { type: 'email', message: '電郵地址不正確', trigger: ['blur', 'change'] }
            ],
            password: [
              { required: true, message: '請輸入用戶密碼', trigger: 'blur' },
            ],
          }
        };
      },
      created() {
        //检查是否登录,如果已经登录,则跳转到聊天页面
        if(isLoggedIn()){
          this.$router.push('/chat');
        }
        this.ruleForm.refcode = this.$route.query.refcode;
      },
      methods: {
        validateNoChinese(rule, value, callback) {
          if (/[\u4e00-\u9fa5]/.test(value)) {
            callback(new Error('用戶名稱不允許包含漢字'));
          } else {
            callback();
          }
        },
        submitForm(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              axios.post(this.base_api+'/register', this.ruleForm)
              .then(response => {
                // 处理响应数据,自动登录
                console.log(response.data);
                this.$router.push('/login');
              })
              .catch(error => {
                console.log(error.response.data);
                // 处理错误情况
                if(error.response.data == "Duplicate key value"){
                  this.$message.error('該郵箱已經被註冊!');
                }else if(error.response.data == "Invalid referral code"){
                  this.$message.error('邀請碼已失效，请删除邀请码!');
                }else{
                  this.$message.error('註冊失敗,请稍后重试!');
                }
              });
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        }
      }
    }
  </script>
  <style scoped>
  .warp-content {
    margin:2rem auto;
    border:1px dashed #ccc;
    text-align: center;
  }
  </style>