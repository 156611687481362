// router.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from './components/HomePage.vue';
import AboutPage from './components/AboutPage.vue';
import LogIn from './components/LogIn.vue';
import Chat from './components/ChatPage.vue';
import RegIster from './components/RegIster.vue';
import ManageLogin from './components/ManageLogin.vue';
import Manage from './components/ManagePage.vue';
import { isLoggedIn } from './authService';

Vue.use(VueRouter);

const routes = [
  { path: '/', component: HomePage},
  { path: '/about', component: AboutPage },
  { path: '/login', component: LogIn },
  { path: '/manager_tomie', component: ManageLogin },
  { path: '/manage', component: Manage , meta: { requiresAuth_m: true }},
  { path: '/reg', component: RegIster },
  { path: '/chat', component: Chat, meta: { requiresAuth: true } }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth_m)) {
    if (!isLoggedIn(true)) {
      next({
        path: '/manager_tomie',
       });
    } else {
      next();
    }
  }
  // 检查即将进入的路由是否需要认证
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 调用你的认证服务来检查用户是否已登录
    if (!isLoggedIn()) {
      // 如果用户未登录，静默地重定向到登录页面
      next({
        path: '/login',
       });
    } else {
      // 如果用户已登录，允许访问
      next();
    }
  } else {
    // 如果不需要认证，则直接进入目标路由
    next();
  }
});


export default router;
