<!-- Home.vue -->
<template>
    <div class="index-warp">
      <div class="home-head" ref="homeHead">
        <p class="hacker-text custom-font" v-for="(text, index) in texts" :key="index" :style="{ top: `${getRandomTop()}px`, left: `${getRandomLeft()}px`, animation: `flicker-${index} ${getRandomDuration()}s infinite` }">{{ text }}</p>
        <YinYang />
      </div>
      <el-button icon="el-icon-s-promotion
" class="index-button" size="medium" type="primary" @click="goToAbout">與大師交談</el-button>
  <div class="index-content">
    <dl class="index-content-list">
      <dt class="custom-font index-list-title">智慧</dt>
      <dd>陳大師採用ChatGPT4.0技術作為基礎，在其基礎上進行了基於RAG技術的智能增強，重點在占卜命理方面知識加強，相較於通用的人工智能，在風水命理領域更加專業。</dd>
    </dl>
    <dl class="index-content-list">
      <dt class="custom-font index-list-title">人性</dt>
      <dd>陳大師不僅是人工智能助手，亦是具有鮮明性格的朋友，除了命理推算，有什麼煩惱事也盡可與之交談，將網站保存書籤，隨時隨地開啟您的樹洞。</dd>
    </dl>
    <dl class="index-content-list">
      <dt class="custom-font index-list-title">革新</dt>
      <dd>不同於傳統的風水命理網站，陳大師無需繁瑣的填寫表單，也不用關心不懂的專業名詞，一切只需要使用正常的聊天模式，即可為您查詢。</dd>
    </dl>
  </div>
  <el-button icon="el-icon-s-promotion
" class="index-button" size="medium" type="primary" @click="goToAbout">與大師交談</el-button>
    </div>
  </template>
  <style scoped></style>
  <script>
  import YinYang from './YinYang.vue';
  export default {
    data() {
      return {
        texts: [
         ]
      };
    },
    mounted() {
      this.$notify({
          title: '版本更新:',
          message: '* 新增AI语音播报功能，欢迎体验!',
          type: 'success'
        });
      // 模拟动态改变文字内容
      setInterval(() => {
        this.texts = [
          "道可道，非常道",
          "名可名，非常名",
          "無名天地之始",
          "有名萬物之母",
          "故常無欲以觀其妙",
          "常有欲以觀其徼",
          "此兩者同出而異名",
          "同謂之玄",
          "玄之又玄",
          "眾妙之門"
        ];
      }, 5000); // 每隔3秒更新一次文字内容
    },
    components: {
      YinYang,
    },
    methods: {
      goToAbout() {
        this.$router.push('/chat').catch(() => {});
      },
      getRandomTop() {
        return Math.floor(Math.random() * 200);
      },
      getRandomLeft() {
        return Math.floor(Math.random() * (this.$refs.homeHead ? this.$refs.homeHead.offsetWidth-100 : 0));
      },
      getRandomDuration() {
        return (Math.random() * 2 + 1).toFixed(2); // 随机生成1到6之间的数作为动画持续时间
      }
    },
  };
  </script>
  <style scoped>
  @font-face {
  font-family: 'LuoShenHua';
    src: url('../assets/AaLuoShenHua-2.woff') format('woff');
    /* 其他格式的字体文件 */
  }

  .custom-font {
    font-family: 'LuoShenHua', cursive; /* 使用自定义字体 */
  }
  .index-list-title {
    font-size: 6rem;
    color: #3c3c3c;
    text-align: center;
    float: left;
    padding-left: 2%;
  }
  .index-content-list {
    width: 94%;
    border: 1px solid #954C3A;
    border-radius: 2rem;
    padding: 3%;
    margin: 0 auto 2rem auto;
    clear: both;
    height: auto;
    overflow: auto;
  }

  .index-content-list dd {
    text-align: left;
    float:right;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #3c3c3c;
    width: 40%;
    padding-right: 4%;
    padding-top: 1%;
  }

  @media (max-width: 768px) {
    .index-list-title {
      float: none;
      width: 100%;
    }
    .index-content-list dd {
      float: none;
      display: block;
      width: 80%;
      padding-right: 0;
    }
  }
  .index-warp {
    width: 100%;
    text-align: center;
  }
  .home-head {
    position: relative;
    width: 100%;
    height: 200px;
    margin: 5rem auto;
    text-align: center;
    display: flex;
    justify-content: center;;
  }
  .index-button {
    margin:0 auto 5rem auto;
    font-size: 1.2rem;
  }
  .hacker-text {
  width:1.4rem;
  writing-mode: vertical-rl;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #3c3c3c;
  font-size: 1.2rem;
  opacity: 0.3; /* 初始时文字透明度很低 */
}

@keyframes flicker {
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
}

@keyframes flicker-0 {
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
}

@keyframes flicker-1 {
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
}

@keyframes flicker-2 {
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
}
.hacker-text.show {
  animation: flicker 1s infinite; /* 使用动画让文字若隐若现 */
}
  </style>


  