<!-- App.vue -->
<template>
  <div id="app">
    <el-row id="nav-top">
      <el-col :span="8">
        <div class="logo"><h2>陳大師-AI算命</h2></div>
      </el-col>
      <el-col :span="16" justify="end">
        <el-menu default-active="/" background-color="#CECECE" :router="true" mode="horizontal">
          <el-menu-item data-tianji-event="app-gochat" index="/about"><i class="el-icon-s-custom"></i><span>關於大師</span></el-menu-item>
          <el-menu-item data-tianji-event="app-goabout" index="/"><i class="el-icon-chat-dot-square"></i><span>請大師</span></el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
    <router-view></router-view>
    <div class="footer">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">2024@陳大師-AI算命 | 又一個命理AI Agent</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>
<style src="./assets/gloable.css"></style>
<style scoped>
  .el-menu-item{
    font-size: 1rem;
  }
  .el-menu--horizontal>.el-menu-item {
    float: right;
  }
  #app {
    width: 90%;
    margin:0 auto;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: 0px;
  }
  #nav-top {
    border-bottom: 1px solid #e6e6e6;
  }
  #nav-top .logo {
    height: 60px;
    width: 160px;
    overflow: hidden;
    background: url("./assets/logo.png") no-repeat center center; /* 背景图片居中显示 */
    background-size: contain; /* 背景图片等比缩放填充整个容器 */
  }
  #nav-top .logo h2 {
    text-indent: -15rem;
    display: none;
  }
  .footer {
    height: 40px;
    line-height: 40px;
    margin:2rem auto;
    text-align: center;
  }
  @media (max-width: 992px) {
  .el-menu li span {
    display: none;
  }
  #nav-top .logo {
    width: 130px;
    height: 40;
    background: url("./assets/logo_small.png") no-repeat center center; /* 背景图片居中显示 */
    background-size: contain;
  }
}
</style>
