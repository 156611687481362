<template>
    <el-container class="warp-content">
      <el-tabs tab-position="left" style="width:100%;overflow:auto;height: 600px;">
      <el-tab-pane label="网站状态">网站状态</el-tab-pane>
      <el-tab-pane label="用户管理">
        <el-container class="manage-main">
          <el-header class="manage-top"></el-header>
          <el-main class="manage-content">
            <el-table
            :data="usersData"
            style="width: 100%">
            <el-table-column
              prop="_id"
              label="id"
              width="280">
            </el-table-column>
            <el-table-column
              prop="username"
              label="用户名"
              width="180"
              >
            </el-table-column>
            <el-table-column
              prop="email"
              label="电邮"
             >
            </el-table-column>
            <el-table-column
              prop="count"
              label="免费点数"
             >
            </el-table-column>
            <el-table-column
              prop="cardcount"
              label="付费点数"
             >
            </el-table-column>
            <el-table-column
              prop="referralCode"
              label="邀请码"
             >
            </el-table-column>
            <el-table-column
              prop="referralPeople"
              label="已邀请人数">
            </el-table-column>
          </el-table>
          </el-main>
        </el-container>
      </el-tab-pane>
      <el-tab-pane label="发卡管理">
        <el-container class="manage-main">
          <el-header class="manage-top"><el-button @click="add_dialogFormVisible=true" type="primary">增加</el-button><el-button @click="del_dialogFormVisible=true" type="primary">删除</el-button><el-button @click="sub_export" type="primary">导出</el-button></el-header>
          <el-dialog title="增加卡密" :visible.sync="add_dialogFormVisible">
          <el-form :model="add_form">
            <el-form-item label="点数" :label-width="formLabelWidth">
              <el-input v-model="add_form.cardValue" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="充值钱数" :label-width="formLabelWidth">
              <el-input v-model="add_form.paymoney" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="add_dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="add_submit">确 定</el-button>
          </div>
        </el-dialog>

        <el-dialog title="删除卡密" :visible.sync="del_dialogFormVisible">
          <el-form :model="del_form">
            <el-form-item label="卡密" :label-width="formLabelWidth">
              <el-input v-model="del_form.cardkey" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="del_dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="del_submit">确 定</el-button>
          </div>
        </el-dialog>

        <el-dialog title="导出卡密" :visible.sync="ex_dialogFormVisible">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="cardall">
          </el-input>
          <div slot="footer" class="dialog-footer">
            <el-button @click="ex_dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="ex_dialogFormVisible = false">确 定</el-button>
          </div>
        </el-dialog>
        
          <el-main class="manage-content">
            <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
              prop="_id"
              label="id"
              width="280">
            </el-table-column>
            <el-table-column
              prop="cardkey"
              label="卡密"
              width="380"
              >
            </el-table-column>
            <el-table-column
              prop="cardValue"
              label="点数"
             >
            </el-table-column>
            <el-table-column
              prop="paymoney"
              label="价格"
             >
            </el-table-column>
            <el-table-column
              label="状态"
              :formatter="row => row.isSummer ? '已消费' : '未消费'">
            </el-table-column>
            <el-table-column
              prop="SummerUser"
              label="消费者">
            </el-table-column>
          </el-table>
          </el-main>
        </el-container>
      </el-tab-pane>
      <el-tab-pane label="配置管理">配置管理</el-tab-pane>
    </el-tabs>
    </el-container>
    </template>
    
    <script>
const serverHost = process.env.VUE_APP_serverHost;
const userproxyports = process.env.VUE_APP_userproxyports;
const userprefix = process.env.VUE_APP_userprefix;
    import axios from 'axios';
    export default {
        data() {
          return {
            base_api: userprefix+'://'+serverHost+':'+userproxyports+'/api',
            add_dialogFormVisible:false,
            del_dialogFormVisible:false,
            ex_dialogFormVisible:false,
            token: localStorage.getItem('m_authToken'),
            add_form: {
              cardValue: '',
              paymoney: ''
            },
            cardall: '',
            del_form: {
              cardkey: ''
            },
            usersData:[
              ],
            tableData:[
            ],
          }
        },
        created(){
            
        },
        mounted(){
          this.getAllcard();
          this.getAlluser();
        },
        methods: {
          sub_export(){
            axios.get(this.base_api+'/getcard',{
                    headers: {
                        'Authorization': 'Bearer '+this.token
                    }
                }).then(res=>{
              console.log(res.data);
              let all = ""
              for(var i=0;i<res.data.length;i++){
                all += res.data[i].cardkey+'\n';
              }
              this.cardall = all;
              this.ex_dialogFormVisible = true;
            }).catch(error=>{
              console.log(error.response.data.message);
              this.$message({
                message: '获取卡密失败！',
                type: 'warning'
              });
            })
          },
          del_submit(){
            console.log(this.del_form);
            this.del_dialogFormVisible = false;
            axios.post(this.base_api+'/deletecard',{cardkey: this.del_form.cardkey},{headers: { 'Authorization': 'Bearer '+this.token } }).then(response=>{
              console.log(response.data.message);
              this.$message({
                message: '删除卡密成功！',
                type: 'success'
              });
              this.getAllcard();
            }).catch(error=>{
              console.log(error.response.data.message);
              this.$message({
                message: '删除卡密失败！',
                type: 'warning'
              });
            });
          },
          add_submit(){
            this.add_dialogFormVisible = false;
            axios.post(this.base_api+'/creatcard',{ 
    cardValue: this.add_form.cardValue, 
    paymoney: this.add_form.paymoney 
  },{ 
    headers: { 'Authorization': 'Bearer '+this.token } 
  }).then(response=>{
              console.log(response.data);
              this.$message({
                message: '创建卡密成功！',
                type: 'success'
              });
              this.getAllcard();
            }).catch(error=>{
              console.log(error.response.data.message);
              this.$message({
                message: '创建卡密失败！',
                type: 'warning'
              });
            });
          },
          getAllcard(){
            axios.get(this.base_api+'/getcard',{
                    headers: {
                        'Authorization': 'Bearer '+this.token
                    }
                }).then(response=>{
              console.log(response);
              this.tableData = response.data;
            }).catch(error=>{
              console.log(error.response);
              this.$message({
                message: '获取卡密失败！',
                type: 'warning'
              });
            });
          },
          getAlluser(){
            axios.get(this.base_api+'/getuser', {
                    headers: {
                        'Authorization': 'Bearer '+this.token
                    }
                }).then(response=>{
              console.log(response.data);
              this.usersData = response.data.user;
            }).catch(error=>{
              console.log(error.response.data);
              this.$message({
                message: '获取用户失败！',
                type: 'warning'
              });
            });
          },
        }
      }
    </script>
    <style scoped>
    .warp-content {
      margin:2rem auto;
      border:1px dashed #ccc;
      text-align: center;
    }
    
    </style>