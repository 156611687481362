import jwtDecode from 'jwt-decode';

export function isLoggedIn(isM) {
    //isM是否为管理后台
    let token = localStorage.getItem('authToken');
    if(isM){
        token = localStorage.getItem('m_authToken');
    }
    
    if (!token) {
        return false;
    }

    try {
        // 解码令牌
        const decoded = jwtDecode(token);

        // 检查令牌是否过期
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            // 令牌已过期
            return false;
        }

        // 令牌有效且未过期
        return true;
    } catch (err) {
        // 如果令牌无效，jwtDecode() 会抛出一个错误
        return false;
    }
}